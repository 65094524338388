import { useCallback } from 'react';

import { LinkFieldValue } from '@sitecore-jss/sitecore-jss-react';

import { getCombinedLinkFieldValueHref } from '@common/link';
import { useRouter } from '@dxp-next';

export type QueryParams = Partial<Record<string, string | number | boolean>>;
export type LinkType = LinkFieldValue | string;
interface Props {
  link?: LinkType;
  queryParams?: QueryParams;
}

const createQueryString = (queryParams?: QueryParams) => {
  if (!queryParams) {
    return '';
  }
  const params = new URLSearchParams();
  Object.entries(queryParams).forEach(([key, value]) => {
    params.append(key, String(value ?? ''));
  });
  return params.toString();
};
export const useRedirect = () => {
  const router = useRouter();

  const redirect = useCallback(
    ({ queryParams, link }: Props) => {
      if (!link) return;
      const queryString = createQueryString(queryParams);
      if (typeof link === 'string') {
        window.location.href = `${link}${queryString ? `?${queryString}` : ''}`;
        return;
      }
      const linkValue = { ...link, querystring: link.querystring ?? '' };
      if (queryString) {
        linkValue.querystring = linkValue.querystring ? `${linkValue.querystring}&${queryString}` : `${queryString}`;
      }
      const href = getCombinedLinkFieldValueHref(linkValue);

      if (linkValue.linktype === 'external') {
        window.location.href = href;
        return;
      }

      router.push(href);
    },
    [router],
  );

  return redirect;
};
